//BRAND
export const name = 'WeClass';
export const company = 'WeClass';
export const websiteURL = 'https://weclass.me';
export const serverURL = 'https://weclassai.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/weclass-641e6.appspot.com/o/logo_weclass.png?alt=media&token=42184e92-2048-473a-a241-0be4f531175a';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 39;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 199;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Steve Lourence";
export const profession = 'CEO at Scale';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-3WA86999RM014512VMYJL6FQ";
export const paypalPlanIdTwo = "P-5UE225497C4730304MYJMBTQ";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";
